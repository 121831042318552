<template>
  <teleport to="body">
    <job-person-spec-modal
      @closeModal="toggleJobSpecModal(false)"
      v-if="jobSpecModalOpen"
      :jobSpecification="getHiringRequest.job_specification"
      :personSpecification="getHiringRequest.person_specification"
    />
  </teleport>
  <screen-split-view v-if="!formIsLoading">
    <template v-slot:leftSide>
      <staff-request-form
        :data="getHiringRequest"
        @toggleJobSpecModal="toggleJobSpecModal(true)"
      />
    </template>
    <template v-slot:rightSide>
      <!-- HALF WAY POINT -->
      <div>
        <!-- Request Form -->
        <div class="flex justify-between p-2 mb-2">
          <div>
            <h1 class="font-bold">
              {{ getHiringRequest.practice.practice_name }}
            </h1>
            <p class="">Department of Administration</p>
          </div>
          <div
            v-if="!isEmpty"
            class="border rounded-3xl px-2 py-1 h-9 text-white"
            :class="
              this.getHiringRequest.status === 'approved'
                ? 'bg-lightGreen border-lightGreen'
                : this.getHiringRequest.status === 'escalated'
                ? 'bg-orange border-orange'
                : this.getHiringRequest.status === 'declined'
                ? 'bg-red border-red '
                : ''
            "
          >
            {{ capitalizeFirstLetter(this.getHiringRequest.status) }}
          </div>
        </div>

        <div class="flex w-full mb-2">
          <!-- <div class="text-center text-grey bg-white rounded-xl ml-2 shadow-md">
          <p class="text-grey font-semibold text-md mx-3">Fixed Term</p>
          <div class="relative">
            <span class="material-icons text-6xl mx-3 text-orange">
              person
            </span>
          </div>
        </div> -->
          <div v-if="!singleDeptIsLoading" class="w-full px-2">
            <contract-type-horizontal
              :data="getSingleDepartment"
              :bgColour="'bg-white'"
            />
          </div>
          <template v-else>
            <horizontal-card-skeleton />
          </template>
        </div>
        <!-- Department Structure Table -->
        <div
          v-if="!singleDeptIsLoading"
          class="bg-white mx-2 rounded-xl shadow-md"
        >
          <div class="pl-4 py-4">
            <h2 class="text-grey font-bold">Department Structure</h2>
            <h3 class="text-grey">
              Head of Department:
              <!-- {{`${getSingleDepartment.department_head.first_name} ${user.department_head.last_name}`}} -->
              {{
                `${getSingleDepartment.department_head.profile.first_name}
                            ${getSingleDepartment.department_head.profile.last_name}`
              }}
            </h3>
            <h3 class="text-grey">Department of Administration</h3>
          </div>

          <table class="w-11.5/12 m-auto text-grey mb-5">
            <tr class="text-left">
              <th class="pb-5 text-lg border-b-2 pl-2">EMPLOYEE</th>
              <th class="pb-5 text-lg border-b-2">JOB TITLE</th>
            </tr>
            <tr
              class="m-1 my-1 border-b-2 w-full"
              v-for="(user, index) in getSingleDepartment.users"
              :key="index"
            >
              <td class="flex items-center w-1/2">
                <div class="w-1/12 px-2 mr-5">
                  <span class="material-icons text-orange"> person </span>
                </div>
                <p>
                  {{ `${user.profile.first_name} ${user.profile.last_name}` }}
                </p>
              </td>
              <td class="w-1/2">
                {{ user.profile.primary_role }}
              </td>
            </tr>
          </table>
          <!-- <my-pagination
            :options="options"
            v-model="page"
            :records="34"
            :per-page="10"
            @paginate="myCallback"
          >
          </my-pagination> -->
        </div>

        <div v-if="isEmpty" class="m-auto w-11.5/12 mb-2">
          <div class="flex justify-between items-center">
            <label for="status">Please select approval type:</label>
          </div>
          <drop-down
            :disabled="!isEmpty"
            v-model="payload.status"
            :values="dropDownOptions"
            :onChange="resolveOnChangeDecision"
            class="border"
            :class="
              v$.payload.status.$error === true
                ? 'border-red'
                : 'border-darkGrey'
            "
          />
        </div>
        <!-- Decision Reason -->
        <div
          v-if="optionSelection"
          class="bg-white rounded-xl m-2 pb-2 flex flex-col shadow-md"
        >
          <h3 class="font-bold m-2 text-lg">Decision Reasoning</h3>
          <div class="flex">
            <div class="m-auto w-11.5/12 mb-2">
              <label for="status">Please select decision:</label>
              <drop-down
                :disabled="!isEmpty"
                selectName="status"
                v-model="payload.decision_reason"
                :values="optionSelection"
                :onChange="resolveOnChangeReason"
                class="border"
                :class="
                  v$.payload.decision_reason.$error === true
                    ? 'border-red'
                    : 'border-darkGrey'
                "
              />
            </div>
          </div>
          <label class="pl-2" for="status">Decision comment*:</label>
          <div
            v-if="getHiringRequest.status != 'approved'"
            class="w-11.5/12 m-auto border"
            :class="
              v$.payload.decision_comment.$error === true
                ? 'border-red'
                : 'border-darkGrey'
            "
          >
            <QuillEditor
              style="min-height: 250px"
              toolbar="essential"
              theme="snow"
              content-type="html"
              v-model:content="payload.decision_comment"
            />
          </div>
          <span
            class="pl-4 text-xs text-red"
            v-if="v$.payload.decision_comment.maxLength.$invalid === true"
          >
            {{ v$.payload.decision_comment.maxLength.$message }}
          </span>
          <div
            v-if="getHiringRequest.status === 'approved'"
            class="border border-grey rounded-l mx-2 flex-grow h-60 overflow-scroll"
          >
            <p class="ml-2">
              <span v-html="getHiringRequest.comment"></span>
            </p>
          </div>
        </div>
        <div
          v-if="!isLoading && getHiringRequest.status === 'pending'"
          class="flex justify-center m-2"
        >
          <button class="bg-teal h-12 w-34 font-light text-white rounded-md" @click="showConfirmationDialog"
            >Submit</button
          >
        </div>
      </div>
    </template>
  </screen-split-view>
  <base-confirmation-dialog :show="confirmationDialog" title="Are you sure?">
    <button class="bg-teal h-12 w-34 font-light text-white rounded-md" @click="confirmHiringRequest"
      >Submit</button
    >
    <button
      class="bg-teal h-12 w-34 font-light text-white rounded-md"
      :mode="'cancel'"
      @click="closeConfirmationDialog"
      >Cancel</button
    >
  </base-confirmation-dialog>
</template>

<script>
// import store from "@/store/index.js";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import horizontalCardSkeleton from "@/components/ui/baseComponents/skeletons/horizontalCardSkeleton.vue";
import contractTypeHorizontal from "@/components/ui/baseComponents/contractTypeHorizontal.vue";
import { markRaw } from "vue";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import jobPersonSpecModal from "@/components/modal/jobPersonSpecModal.vue";
import moment from "moment";
import dropDown from "@/components/ui/forms/dropDown.vue";
import staffRequestForm from "@/components/ui/baseComponents/Recruitment/staffRequestForm.vue";
import baseConfirmationDialog from "@/components/ui/baseComponents/baseConfirmationDialog.vue";
import screenSplitView from "@/components/ui/baseComponents/screenSplitView.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers, maxLength } from "@vuelidate/validators";
export default {
  components: {
    QuillEditor,
    staffRequestForm,
    baseConfirmationDialog,
    dropDown,
    contractTypeHorizontal,
    horizontalCardSkeleton,
    jobPersonSpecModal,
    screenSplitView,
  },
  async created() {
    this.isLoading = true;
    this.formIsLoading = true;
    (this.singleDeptIsLoading = true),
      await this.fetchHiringRequest(this.$route.params.staffRequestForm);
    this.isLoading = false;
    await this.fetchAllHiringRequests(null);
    this.formIsLoading = false;
    await this.fetchSingleDepartment(this.getHiringRequest.department_id);
    this.singleDeptIsLoading = false;
  },
  data() {
    return {
      v$: useVuelidate(),
      jobSpecModalOpen: false,
      payload: {
        hiring_request: this.$route.params.staffRequestForm,
        status: null,
        progress: "declined",
        decision_reason: "",
        decision_comment: "",
      },
      formIsLoading: false,
      isLoading: false,
      singleDeptIsLoading: false,
      page: 1,
      options: {
        template: markRaw(customPaginate),
      },
      dropDownOptions: {
        pleaseSelect: "Please Select...",
        approved: "Approve",
        escalated: "Escalate",
        declined: "Declined",
      },
      dropDownEscalateOptions: {
        pleaseSelect: "Please Select...",
        further_input_required: "Further input required",
        business_planning_required: "Business planning required",
      },
      dropDownDeclineOptions: {
        pleaseSelect: "Please Select...",
        info_incorrect: "Information incorrect",
        not_in_budget: "Not in budget",
        start_date_inappropriate: "Start date inappropriate",
        call_to_discuss: "Call to discuss",
      },
      reasonForRecruitment: null,
      confirmationDialog: false,
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   // Check if hiringRequest Object in sotore is NULL.
  //   const objectIsNull = Object.values(
  //     store.getters["MAnewHire/getNewHiringRequest"]
  //   ).every((value) => {
  //     if (value === null) {
  //       return true;
  //     }
  //     return false;
  //   });
  //   // Redirect back to new hire with status error for notification
  //   if (objectIsNull) {
  //     next({
  //       name: "ma-new-hire",
  //       query: { error: "true" },
  //     });
  //   } else {
  //     next();
  //   }
  // },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    convertedTime(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    async fetchAllHiringRequests(status, page) {
      try {
        // this.isLoading = true;
        await this.$store.dispatch("HQnewHire/fetchAllHiringRequests", [
          {
            status: status,
            page,
          },
        ]);
        // this.isLoading = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchHiringRequest(requestFormId) {
      try {
        // this.formIsLoading = true;
        await this.$store.dispatch("HQnewHire/fetchHiringRequest", {
          hiring_request: requestFormId,
        });
        if (this.getHiringRequest && this.getHiringRequest.status) {
          this.payload.status = this.getHiringRequest.status;
        }
        if (this.getHiringRequest && this.getHiringRequest.decision_reason) {
          this.payload.decision_reason = this.getHiringRequest.decision_reason;
        }
        // this.formIsLoading = false;
      } catch (error) {
        this.$router.push({
          name: "hq-recruitment",
          query: { error: "true" },
        });
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchSingleDepartment(deptId) {
      try {
        await this.$store.dispatch("HQnewHire/fetchSingleDepartment", {
          department: deptId,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    myCallback(e) {
      console.log(e);
    },
    toggleJobSpecModal(value) {
      this.jobSpecModalOpen = value;
    },
    resolveOnChangeDecision(data) {
      this.payload.status = data.target.value;
      this.payload.decision_reason = "";
    },
    resolveOnChangeReason(data) {
      console.log(data.target.value);
      this.payload.decision_reason = data.target.value;
    },
    showConfirmationDialog() {
      console.log(this.v$, this.v$.payload.status.$validate(), "...");
      this.v$.payload.status.$validate();
      if (this.payload.status != "approved") {
        this.v$.payload.decision_reason.$validate();
        this.v$.payload.decision_comment.$validate();
        if (
          this.v$.payload.decision_reason.$error ||
          this.v$.payload.decision_comment.$error
        ) {
          return;
        }
      }
      this.confirmationDialog = true;
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false;
    },
    async confirmHiringRequest() {
      try {
        await this.$store.dispatch(
          "HQnewHire/processHiringRequest",
          this.payload
        );
        this.$router.push({
          name: "hq-recruitment",
          query: { success: "true" },
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.$router.push({
          name: "hq-recruitment",
          query: { error: "true" },
        });
      }
    },
  },
  validations() {
    return {
      payload: {
        status: { required },
        decision_reason: { required },
        decision_comment: {
          required,
          maxLength: helpers.withMessage("Max 1000 chars.", maxLength(999)),
        },
      },
    };
  },
  computed: {
    isEmpty() {
      if (this.getHiringRequest && this.getHiringRequest.status === "pending") {
        return true;
      } else return false;
    },
    optionSelection() {
      if (this.getHiringRequest.status === "escalated") {
        return null;
      } else if (this.getHiringRequest.status === "declined") {
        return null;
      } else if (this.payload.status == "declined") {
        return this.dropDownDeclineOptions;
      } else if (this.payload.status == "escalated") {
        return this.dropDownEscalateOptions;
      }
      return null;
    },
    getNewApplicationsHiringRequest() {
      return this.$store.getters["HQnewHire/getNewApplicationsHiringRequest"];
    },
    getHiringRequest() {
      return this.$store.getters["HQnewHire/getHiringRequest"];
    },
    getSingleDepartment() {
      return this.$store.getters["HQnewHire/getSingleDepartment"];
    },
    getJobPersonSpec() {
      return this.$store.getters["HQnewHire/getJobPersonSpec"];
    },
  },
};
</script>
